import React from 'react'
import Note from './Note'

const NotesContainer = () => {
  return (
    <Note />
  )
}

export default NotesContainer
